//section-title
.section-title {
    position: relative;
    z-index: 1;
}

//sect-title
.sect-title {
    font-size: 48px;
    color: $white;
    line-height: 1.17;

    span {
        border-bottom: 2px solid $theme-color;
    }
    @media #{$laptop} {
        font-size: 32px;
    }
    @media #{$lg} {
        font-size: 32px;
    }
    @media #{$md} {
        font-size: 24px;
    }
    @media #{$xs} {
        font-size: 24px;
    }
}

//sec-title-two
.sect-title-two {
    color: $white;
    font-size: 32px;
    font-family: $clash-semibold;
    font-weight: 600;
    @media #{$laptop} {
        font-size: 28px;
    }
    @media #{$lg} {
        font-size: 22px;
    }
    @media #{$md} {
        font-size: 20px;
    }
    @media #{$xs} {
        font-size: 20px;
    }
}

//sub-title
.sub-title {
    color: $white;
    font-size: 17px;
    font-weight: 500;
    font-family: $clash-medium;
}

//sub-title
.sub-title-two {
    color: $prime-color;
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 500;
    font-family: $clash-medium;
}

.section-title-two {
    .sub-title {
        color: $theme-color;
        font-weight: 600;
        font-family: $clash-semibold;
        text-transform: uppercase;
    }
}

//blog-title
.blog-title {
    color: $white;
    font-size: 24px;
    font-family: $clash-semibold;
    font-weight: 600;
    line-height: 1.3;
    display: inline-block;
    margin-bottom: 12px;
    @media #{$laptop} {
        font-size: 20px;
    }
    @media #{$lg} {
        font-size: 20px;
    }
    @media #{$md} {
        font-size: 20px;
    }
    @media #{$xs} {
        font-size: 20px;
    }
    &:hover {
        color: $white;
    }
}

//semi-title
.semi-title {
    color: $white;
    font-size: 24px;
    font-family: $clash-semibold;
    font-weight: 600;
    @media #{$lg} {
        font-size: 18px;
    }
}

.post-title {
    font-size: 40px;
    color: $white;
    @media #{$laptop} {
        font-size: 28px;
    }
    @media #{$lg} {
        font-size: 28px;
    }
    @media #{$xs} {
        font-size: 24px;
    }
}
