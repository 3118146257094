//latest-case-work
.latest-case-work {
    position: relative;
    z-index: 1;
    .case-shape {
        position: absolute;
        z-index: 1;
        &.shape-1b {
            right: 7.3%;
            top: 80%;
            animation: float-bob infinite 35s linear;
            -webkit-animation: float-bob infinite 35s linear;
        }
        &.shape-2b {
            left: 7.5%;
            top: 52%;
            animation: float-bob infinite 15s linear;
            -webkit-animation: float-bob infinite 15s linear;
        }
        &.shape-3b {
            right: 60%;
            top: 13%;
            animation: rotation infinite 15s linear;
            -webkit-animation: rotation infinite 15s linear;
        }
        &.shape-4b {
            right: 59.5%;
            top: 87%;
        }
    }
    .slick-slider {
        margin: 0 -15px;
    }
}

//case-item
.case-item-one {
    margin-left: 15px;
    margin-right: 15px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    .case-thumb {
        position: relative;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            background: linear-gradient(180deg, rgba(5, 13, 54, 0) 0%, rgba(5, 13, 54, 0.9) 100%);
            background: -moz-linear-gradient(180deg, rgba(5, 13, 54, 0) 0%, rgba(5, 13, 54, 0.9) 100%);
            background: -o-linear-gradient(180deg, rgba(5, 13, 54, 0) 0%, rgba(5, 13, 54, 0.9) 100%);
            background: -ms-linear-gradient(180deg, rgba(5, 13, 54, 0) 0%, rgba(5, 13, 54, 0.9) 100%);
            background: -webkit-linear-gradient(180deg, rgba(5, 13, 54, 0) 0%, rgba(5, 13, 54, 0.9) 100%);
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }
    .case-content {
        padding: 0 36px 30px 30px;
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        width: 100%;
        @include transition(0.3s);
        span {
            color: $white;
            text-decoration: underline;
        }
        .sect-title-two {
            color: $white;
            display: inline-block;
            margin-top: 15px;
            margin-bottom: 15px;

            &:hover {
                text-decoration: underline;
            }
        }
        p {
            color: $white;
            margin-bottom: 20px;
            display: none;
            transform: scale(0);
            -webkit-transform: scale(0);
            -moz-transform: scale(0);
            -ms-transform: scale(0);
            -o-transform: scale(0);
            @include transition(0.3s);
        }
        .case-btn {
            color: $theme-color;
            font-size: 20px;
            font-weight: 700;
            background: $white;
            display: inline-block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            text-align: center;
            line-height: 50px;
            display: none;
            transform: scale(0);
            -webkit-transform: scale(0);
            -moz-transform: scale(0);
            -ms-transform: scale(0);
            -o-transform: scale(0);
            @include transition(0.3s);
            &:hover {
                color: $white;
                background-color: $theme-color;
            }
        }
        @media #{$laptop} {
            padding-bottom: 15px;
            .sect-title-two {
                font-size: 24px;
                margin-bottom: 8px;
            }
        }
        @media (max-width: 1199.98px) {
            padding: 0 15px 10px 15px;
            .sect-title-two {
                font-size: 24px;
                margin-bottom: 0;
            }
            p {
                font-size: 15px;
                line-height: 26px;
                margin-bottom: 5px;
            }
        }
    }
    &:hover {
        .case-content {
            background: linear-gradient(180deg, rgba(5, 13, 54, 0) 0%, #913bff 100%);
            bottom: 0;
            p,
            .case-btn {
                display: inline-block;
                transform: scale(1);
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
            }
        }
    }

    @media (max-width: 1600px) {
        margin-right: 15px;
        margin-left: 15px;
    }
}

//techy-work-process
.techy-wrok-process {
    position: relative;
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        width: 529px;
        height: 529px;
        right: 34.53%;
        top: 29%;
        background: #913bff;
        opacity: 0.2;
        filter: blur(300px);
        z-index: -1;
    }
}

//process-img-wrapper
.process-img-wrapper {
    position: relative;
    z-index: 1;
    img {
        @media (max-width: 1200px) {
            width: 100%;
        }
    }
    .call-box {
        background: $white;
        padding: 83px 26px 35px 26px;
        display: inline-block;
        width: 280px;
        text-align: center;
        position: absolute;
        bottom: 60px;
        right: 0;
        z-index: 1;
        animation: float-bob infinite 15s linear;
        -webkit-animation: float-bob infinite 15s linear;
        .icon {
            display: inline-block;
            width: 110px;
            height: 110px;
            background: $prime-color;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            text-align: center;
            line-height: 110px;
            position: absolute;
            top: -26%;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        .sect-title-two {
            color: $heding;
            margin-bottom: 17px;
        }
        @media #{$xs} {
            bottom: 0;
            right: 0;
            left: 0;
            margin: 0 auto;
            z-index: 1;
            animation: none;
            -webkit-animation: none;
        }
    }
}

//work-bg-three
.work-bg-three {
    position: relative;
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        width: 500px;
        height: 500px;
        right: 0;
        left: 0;
        margin: 0 auto;
        top: 8%;
        background: #913bff;
        opacity: 0.2;
        filter: blur(300px);
        z-index: -1;
    }
    &::after {
        content: "";
        position: absolute;
        width: 500px;
        height: 500px;
        right: 0;
        left: 0;
        margin: 0 auto;
        top: 60%;
        background: #104cba;
        opacity: 0.2;
        filter: blur(300px);
        z-index: -1;
    }
    .work-shape-c {
        position: absolute;
        z-index: -1;
        &.shape-one {
            right: 17.3%;
            top: 59%;
            top: 59%;
            animation: float-bob infinite 35s linear;
            -webkit-animation: float-bob infinite 35s linear;
        }
        &.shape-two {
            right: 78.5%;
            top: 91%;
            animation: float-bob infinite 15s linear;
            -webkit-animation: float-bob infinite 15s linear;
        }
        &.shape-three {
            right: 58.4%;
            top: 57%;
            animation: rotation infinite 15s linear;
            -webkit-animation: rotation infinite 15s linear;
        }
        &.shape-four {
            right: 24.2%;
            top: 93%;
        }
    }
}

//techy-security
.techy-security {
    position: relative;
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        width: 518px;
        height: 518px;
        right: 49%;
        top: 6%;
        background: #913bff;
        opacity: 0.2;
        filter: blur(300px);
        z-index: -1;
    }
}

//techy-project-one
.techy-project-one {
    .feature-slider-one {
        .feature-item::before {
            height: 75.3%;
        }
    }
}

//portfolio-menu
.portfolio-menu {
    .gf_btn {
        cursor: pointer;
        color: $white;
        font-size: 16px;
        background: rgba($white, 0.1);
        line-height: 16px;
        border: 0;
        display: inline-block;
        padding: 17px 34px;
        margin: 7px;
        position: relative;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            background: $white;
            height: 100%;
            width: 2px;
            left: 0;
            top: 0;
            z-index: -1;
            @include transition(0.3s);
        }
        &:hover,
        &.react-tabs__tab--selected {
            &::before {
                width: 100%;
                background: $theme-color;
                border: 0;
                border-radius: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                -ms-border-radius: 0;
                -o-border-radius: 0;
            }
        }
        @media #{$xs} {
            font-size: 15px;
            padding: 17px 20px;
            margin: 7px 2px;
        }
    }
}

//project-info
.project-info {
    background: url(../img/work/big-img-01.jpg) no-repeat;
    background-size: cover;
    background-position: center;
}
.info-box {
    background: radial-gradient(97.57% 210.75% at 0.9% 2.98%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    backdrop-filter: blur(42px);
    display: inline-block;
    padding: 40px;
    margin: 20px 50px 20px 0;
    @media #{$xs} {
        padding: 40px 15px;
        margin: 20px 0 20px 0;
    }
}

.info-list {
    margin-bottom: 15px;
    span {
        color: $white;
        font-weight: 400;
        font-size: 16px;
    }
    h5 {
        color: $white;
        font-family: $clash-semibold;
        font-weight: 600;
    }
}

//project-tabpanel-masonry
.project-tabpanel-masonry.react-tabs__tab-panel--selected .row {
    width: inherit !important;
}
