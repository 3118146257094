/*-----------------------------------------------------------------------------------

    Template Name:  Techy – IT Solution & Web Security React Template
    Author: Hamina-Themes
    Version: 1.0
    Developed By: Hasan Uddin
    Mail:hasanlab1999@hotmail.com

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme default CSS
	02. variables
    03. mixins
    04. common
    05. overlay
    06. header
    07. breadcrumb 
    08. mobile-menu
    09. typography
    10. search
    11. custom-animation
    12. btn
    13. section-title
    14. theme-banner
    15. marque
    16. brand
    17. block-style
    18. about
    19. slick-nav
    20. testimonial
    21. counter
    22. blog
    23. service
    24. case-study
    25. video
    26. team
    27. form
    28. price
    29. call-to-action
    30. widget
    31. progress-bar
    32. shop
    33. faq
    34. error
    35. footer

-----------------------------------------------------------------------------------*/

@import url(../css/all.min.css);
@import url(../fonts/bootstrap-icons/font-css.css);
@import url(../fonts/custom-font/css/clash-display.css);

@import "variables";
@import "mixins";
@import "common";
@import "overlay";
@import "header";
@import "breadcrumb";
@import "mobile-menu";
@import "typography";
@import "search";
@import "custom-animation";
@import "btn";
@import "section-title";
@import "theme-banner";
@import "marque";
@import "brand";
@import "block-style";
@import "about";
@import "feature";
@import "slick-nav";
@import "testimonial";
@import "counter";
@import "blog";
@import "service";
@import "case-study";
@import "video";
@import "team";
@import "form";
@import "price";
@import "call-to-action";
@import "widget";
@import "progress-bar";
@import "shop";
@import "faq";
@import "error";
@import "footer";
