//block-style-one
.block-style-one {
    h2 {
        color: $white;
        font-size: 48px;
        line-height: 1.25;
        @media #{$laptop} {
            font-size: 32px;
        }
        @media #{$lg} {
            font-size: 24px;
        }
        @media #{$md} {
            font-size: 24px;
        }
        @media #{$xs} {
            font-size: 24px;
        }
    }
    p {
        color: $body-text-color;
    }

    .block-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $white;
        padding-top: 25px;
        margin-top: 20px;
        li {
            &:first-child {
                margin-right: 30px;
            }
        }
        a {
            display: flex;
            align-items: center;
            color: $second-color;
            font-size: 20px;
            line-height: 25px;
            font-family: $clash-medium;
            font-weight: 500;
            background: $white;
            padding: 11px 22px 10px 20px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;

            img {
                width: 59px;
                height: 59px;
                background: linear-gradient(180deg, rgba(16, 76, 186, 0.1) 0%, rgba(16, 76, 186, 0.1) 100%);
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
            }
        }
        @media #{$laptop} {
            display: block;
            li:first-child {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
        @media #{$lg} {
            display: block;
            li:first-child {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
        @media #{$xs} {
            display: block;
            li:first-child {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
        @media #{$sm} {
            display: flex;
            li:first-child {
                margin-right: 15px;
                margin-bottom: 0;
            }
        }
    }
    .box-two {
        a {
            color: $white;
            background: rgba($white, 0.05);
            &:hover {
                color: $heding;
                background: $white;
            }
        }
    }
}

//block-style-two
.block-style-two {
    position: relative;
    z-index: 2;

    .news-shape-one {
        position: absolute;
        left: 0;
        right: 0;
        top: -70px;
        margin: 0 auto;
        z-index: -1;
        animation: rotation infinite 24s linear;
        -webkit-animation: rotation infinite 24s linear;
        @media #{$lg} {
            top: -140px;
        }
        @media #{$md} {
            display: none;
        }
        @media #{$xs} {
            display: none;
        }
    }
    h3 {
        color: $white;
        font-size: 64px;
        line-height: 1.3;
        margin-bottom: 60px;
        @media #{$laptop} {
            font-size: 48px;
        }
        @media #{$lg} {
            font-size: 32px;
        }
        @media #{$md} {
            font-size: 32px;
        }
        @media #{$xs} {
            font-size: 24px;
        }
    }
}

//block-style-three

.block-style-three {
    position: relative;
    z-index: 2;
}

//block-style-six
.block-style-six {
    .process-box {
        overflow: hidden;
        .img {
            display: inline-block;
            width: 100px;
            height: 100px;
            background: rgba($prime-color, 0.1);
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            text-align: center;
            line-height: 100px;
            float: left;
            margin-right: 30px;
            @include transition(0.3s);
        }
        .text-wrapper {
            overflow: hidden;
            .sect-title-two {
                margin-bottom: 4px;
            }
        }
        &:hover {
            .img {
                background: $theme-color;
            }
        }
        @media #{$xs} {
            text-align: center;
            .img {
                float: none;
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
    }
}

//block-style-seven
.block-style-seven {
    .icon-box {
        margin-top: 25px;
        margin-bottom: 3px;
        span {
            display: inline-block;
            margin-right: 20px;
            background: rgba($theme-color, 0.2);
            width: 68.97px;
            height: 80px;
            text-align: center;
            line-height: 80px;
            border-radius: 5px;
            transform: matrix(-1, 0, 0, 1, 0, 0);
            clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
            &:hover {
                background: $theme-color;
            }
        }
    }
    @media (max-width: 1200px) {
        .icon-box {
            span {
                width: 54px;
                height: 65px;
                line-height: 65px;
                margin-right: 10px;
            }
        }
    }
}

//block-style-eight
.block-style-eight {
    .block-list {
        li {
            position: relative;
            z-index: 1;
            color: $body-text-color;
            font-size: 17px;
            font-family: $clash-regular;
            font-weight: 400;
            padding-bottom: 26px;
            padding-left: 48px;
            &::before {
                content: "\F272";
                color: $white;
                font-family: "bootstrap-icons";
                font-weight: 700;
                position: absolute;
                font-size: 20px;
                text-align: center;
                left: 0;
                top: 0;
                z-index: 1;
                -webkit-transition: all 0.3s ease 0s;
                -moz-transition: all 0.3s ease 0s;
                -ms-transition: all 0.3s ease 0s;
                -o-transition: all 0.3s ease 0s;
                transition: all 0.3s ease 0s;
                width: 30px;
                height: 30px;
                background: $theme-color;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
            }
        }
    }
}
