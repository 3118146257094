//feature-custom-container
.feature-custom-container {
    max-width: 1565px;
    width: 100%;
    margin: 0 75px 0 auto;
    @media (max-width: 1199.98px) {
        margin: 0 auto;
    }
    .feature-slider {
        .feature-item {
            margin-left: 22.5px;
            margin-right: 22.5px;
            @media #{$xs} {
                margin-left: 15px;
                margin-right: 15px;
            }
        }
    }
}

//feature-slider
.feature-slider {
    .feature-item {
        position: relative;
        z-index: 1;

        .back-bg {
            display: block;
            position: relative;
            z-index: 1;
            &::before {
                content: "";
                position: absolute;
                border: 2px solid;
                background: transparent;
                border-image: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0.02)) 1 1 0;
                border-image: -moz-linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0.02)) 1 1 0;
                border-image: -webkit-linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0.02)) 1 1 0;
                border-image: -o-linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0.02)) 1 1 0;
                border-image: -ms-linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0.02)) 1 1 0;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: -1;
            }
        }
        img {
            padding-top: 30px;
            padding-left: 30px;
        }
        h5 {
            margin-bottom: 15px;
            margin-top: 36px;
            a {
                color: $body-text-color;
                font-size: 20px;
                line-height: 25px;
                font-family: $clash-regular;
                font-weight: 400;
                @include transition(0.3s);
                &:hover {
                    color: $theme-color;
                    border-bottom: 2px solid $theme-color;
                }
            }
        }
        h3 {
            color: $white;
            font-size: 32px;
            font-family: $clash-semibold;
            @media #{$lg} {
                font-size: 24px;
            }
            @media #{$md} {
                font-size: 20px;
            }
            @media #{$xs} {
                font-size: 20px;
            }
        }
        &:hover {
            .back-bg {
                &::before {
                    background: $theme-color;
                    border-image: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0.02)) 0 0 0;
                    border-image: -moz-linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0.02)) 0 0 0;
                    border-image: -webkit-linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0.02)) 0 0
                        0;
                    border-image: -o-linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0.02)) 0 0 0;
                    border-image: -ms-linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0.02)) 0 0 0;
                }
            }
        }
    }
}

//client-feedback
.client-feedback {
    position: relative;
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        width: 481px;
        height: 481px;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 10%;
        background: #913bff;
        opacity: 0.2;
        filter: blur(300px);
        -webkit-filter: blur(300px);
        -moz-filter: blur(300px);
        -o-filter: blur(300px);
        -ms-filter: blur(300px);
    }
}

//feedback-style-one
.feedback-style-one {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 55px;
    padding-right: 46px;

    .rating {
        color: #f2c94c;
        font-size: 20px;
    }
    p {
        color: $white;
        font-size: 32px;
        line-height: 1.4;
        font-family: $clash-medium;
        font-weight: 500px;
        @media #{$laptop} {
            font-size: 25px;
        }
        @media #{$lg} {
            font-size: 20px;
        }
        @media #{$md} {
            font-size: 20px;
        }
        @media #{$xs} {
            font-size: 17px;
        }
    }
    .author {
        img {
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            margin-right: 20px;
        }
        .author-info {
            text-align: left;
            h3 {
                color: $white;
                font-size: 30px;
                font-family: $clash-regular;
                font-weight: 400;
                margin-bottom: 12px;
                @media #{$laptop} {
                    font-size: 20px;
                }
                @media #{$lg} {
                    font-size: 20px;
                }
                @media #{$md} {
                    font-size: 20px;
                }
                @media #{$xs} {
                    font-size: 18px;
                }
            }
            h5 {
                color: $white;
                font-family: $clash-regular;
                font-weight: 400;
                @media #{$xs} {
                    font-size: 16px;
                }
            }
        }
    }
}

//feedback-bg
.feedback-bg {
    position: relative;
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        width: 507px;
        height: 507px;
        right: 46.09%;
        top: 13%;
        background: #913bff;
        opacity: 0.15;
        filter: blur(300px);
        z-index: -1;
    }
}

//feedback-style-two
.feedback-style-two {
    margin-left: 22.5px;
    margin-right: 22.5px;
    padding: 40px;
    background: rgba($prime-color, 0.1);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    h4 {
        color: $white;
        font-size: 24px;
    }

    .author {
        img {
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
            margin-right: 20px;
        }
    }
    @media (max-width: 1600px) {
        margin-left: 15px;
        margin-right: 15px;
        padding: 40px 20px;
        h4 {
            font-size: 20px;
        }
    }
    @media #{$xs} {
        margin-left: 0;
        margin-right: 0;
        p {
            font-size: 16px;
        }
    }
}

.rating {
    a {
        color: #f2c94c;
        font-size: 20px;
        margin: 0 2px;
    }
    span {
        color: $white;
        font-size: 18px;
        margin-left: 3px;
    }
}

//feedback-style-three
.feedback-style-three {
    margin-left: 22.5px;
    margin-right: 22.5px;
    padding: 44px 35px 25px 35px;
    border-radius: 0;
    background: rgba(16, 76, 186, 0.1);
    img {
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        display: inline-block;
    }
    p {
        margin-bottom: 15px;
    }
    h4 {
        color: $white;
        font-size: 24px;
        margin-bottom: 7px;
    }
    @media (max-width: 1200px) {
        margin-left: 15px;
        margin-right: 15px;
        padding: 44px 15px 25px 15px;
    }
    @media #{$xs} {
        margin-left: 0;
        margin-right: 0;
        padding: 44px 0 25px 0;
    }
}
