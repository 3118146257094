/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/

/* 1. Theme default css */

body {
	font-family: $clash-regular;
	font-size: 17px;
	line-height: 30px;
	font-weight: 400;
	font-style: normal;
	color: $body-text-color;
}

.main-page-wrapper {
	overflow-x: hidden;
	background-color: #050d36;
}

// .img,img {
// 	max-width: 100%;
// 	@include transition(.3s);
// }

.f-left {
	float: left;
}
.f-right {
	float: right;
}
.fix {
	overflow: hidden;
}
a,
.button {
	@include transition(0.3s);
}
a:focus,
.button:focus {
	text-decoration: none;
	outline: none;
}
a:focus,
a:hover {
	color: inherit;
	text-decoration: none;
}
a,
button {
	color: inherit;
	outline: medium none;
	text-decoration: none;
}
button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
	outline: 0;
}
.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-family: $clash-bold;
	color: $heding;
	line-height: 1.1;
	margin-top: 0px;
	margin-bottom: 0;
	font-style: normal;
	font-weight: 700;
	text-transform: normal;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit;
	text-decoration: none;
}
h1 {
	font-size: 40px;
}
h2 {
	font-size: 35px;
}
h3 {
	font-size: 28px;
}
h4 {
	font-size: 22px;
}
h5 {
	font-size: 18px;
}
h6 {
	font-size: 16px;
}
.list-none {
	margin: 0px;
	padding: 0px;
}
li {
	list-style: none;
}
p {
	font-size: 17px;
	font-weight: 400;
	line-height: 30px;
	font-family: $clash-regular;
	color: $body-text-color;
	margin-bottom: 0;
}
hr {
	border-bottom: 1px solid rgab($white, 0.1);
	border-top: 0 none;
	margin: 30px 0;
	padding: 0;
}
label {
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
}
*::-moz-selection {
	background: $black;
	color: $white;
	text-shadow: none;
}
::-moz-selection {
	background: $black;
	color: $white;
	text-shadow: none;
}
::selection {
	background: $black;
	color: $white;
	text-shadow: none;
}

/*--
    - Input Placeholder
-----------------------------------------*/
*::-moz-placeholder {
	color: $black;
	font-size: 14px;
	opacity: 1;
}
*::placeholder {
	color: $black;
	font-size: 14px;
	opacity: 1;
}

/*--
    - position Classes
-----------------------------------------*/
.pos-rel {
	position: relative;
}
.pos-abl {
	position: absolute;
}
/*--
    - Common Classes
-----------------------------------------*/
.fix {
	overflow: hidden;
}
.clear {
	clear: both;
}

//style-text
.style-text {
	font-size: 140px;
	line-height: 0.5;
	-webkit-text-fill-color: transparent;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: $body-text-color;
	opacity: 0.1;
	position: absolute;
	left: 0;
	top: -19px;
	text-align: center;
	@media #{$md} {
		font-size: 100px;
	}
	@media #{$xs} {
		font-size: 60px;
	}
}

//overlay-bg

.overlay-bg {
	position: relative;
	z-index: 2;
	&::before {
		content: "";
		position: absolute;
		z-index: -1;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}

//video-icon
.popup-video {
	color: $white;
	background: $prime-color;
	border-radius: 50%;
	font-size: 24px;
	line-height: 83px;
	display: inline-block;
	width: 80px;
	height: 80px;
	text-align: center;
	-webkit-transition: all 0.4s ease-out 0s;
	-moz-transition: all 0.4s ease-out 0s;
	-ms-transition: all 0.4s ease-out 0s;
	-o-transition: all 0.4s ease-out 0s;
	transition: all 0.4s ease-out 0s;
	position: relative;
	z-index: 1;
	animation: pulseBig infinite 5s linear;
	cursor: pointer;
	&:hover {
		color: $white;
		background: $theme-color;
	}
}

// others common css here :)

.dark #preloader {
	background-color: #232323;
}

#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #f7f7f7;
	z-index: 999999;
}

.preloader {
	width: 50px;
	height: 50px;
	display: inline-block;
	padding: 0px;
	text-align: left;
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -25px;
	margin-top: -25px;
}

.preloader span {
	position: absolute;
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	background: $theme-color;
	-webkit-animation: preloader 1.3s linear infinite;
	animation: preloader 1.3s linear infinite;
}

.preloader span:last-child {
	animation-delay: -0.8s;
	-webkit-animation-delay: -0.8s;
}

@keyframes preloader {
	0% {
		transform: scale(0, 0);
		opacity: 0.5;
	}

	100% {
		transform: scale(1, 1);
		opacity: 0;
	}
}

@-webkit-keyframes preloader {
	0% {
		-webkit-transform: scale(0, 0);
		opacity: 0.5;
	}

	100% {
		-webkit-transform: scale(1, 1);
		opacity: 0;
	}
}
//scrollup
.scroll-top {
	background: $theme-color;
	width: 50px;
	height: 50px;
	border: 0;
	position: fixed;
	right: 20px;
	bottom: 50px;
	border-radius: 50%;
	font-size: 18px;
	line-height: 46px;
	text-align: center;
	z-index: 111;
	@include transition(0.3s);
	& i {
		color: $white;
	}
}
